import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.js';
import 'admin-lte/dist/js/adminlte.min.js';
//import 'admin-lte/dist/js/demo.js';

// font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe, faEye, faEyeSlash, faTimes, faChevronRight, faChartArea, faFileUpload, faChartBar, faGavel, faFileContract, faUserCog, faFileInvoice, faBox, faUsers, faPlayCircle,
  faArrowCircleUp, faChevronDown, faShoppingBasket, faBell, faFolderPlus, faFolderMinus, faDotCircle, faSearch, faFileSignature, faPen, faTrashAlt, faPenAlt, faUser, faCaretSquareRight,
  faSort, faSortDown, faSortUp, faPencilAlt, faInbox, faFileAlt, faCheck, faShieldAlt, faHammer, faAngleDown,
  faArrowLeft, faChevronLeft, faFileDownload, faPrint, faDownload, faUserAlt, faUserCircle, faCircle, faCartPlus, faChevronUp,
  faBook, faReceipt, faLock, faCircleNotch, faSpinner, faClone
} from '@fortawesome/free-solid-svg-icons'
import { faLine } from '@fortawesome/free-brands-svg-icons'
// import {  } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGlobe, faEye, faEyeSlash, faTimes, faChevronRight, faChartArea, faFileUpload, faChartBar, faGavel, faFileContract, faUserCog, faFileInvoice, faBox, faUsers, faPlayCircle,
    faArrowCircleUp, faChevronDown, faShoppingBasket, faBell, faFolderPlus, faFolderMinus, faDotCircle, faSearch, faFileSignature, faPen, faTrashAlt, faPenAlt, faUser, faCaretSquareRight,
    faSort, faSortDown, faSortUp, faPencilAlt, faInbox, faFileAlt, faCheck, faShieldAlt, faHammer, faAngleDown,
    faArrowLeft, faChevronLeft, faFileDownload, faPrint, faDownload, faUserAlt, faUserCircle, faCircle, faCartPlus,
    faChevronUp, faBook, faReceipt, faLock, faCircleNotch, faSpinner, faLine, faClone)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// vue table
import {ClientTable, ServerTable} from 'vue-tables-2';
Vue.use(ClientTable);
Vue.use(ServerTable);

// Vue Pagination
import Pagination from 'vue-pagination-2';
Vue.component('VuePagination', Pagination);


Vue.config.productionTip = false


// Vue axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// Vue sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// toast
import Swal from 'sweetalert2'
const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
window.toast = toast;

// Vue Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay';
Vue.component('PageLoading', Loading);
Vue.use(Loading)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.use(DatePicker)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

